<template>
    <div class="page404">
        <h2 class="page404__title" v-html="title"></h2>
        <p class="page404__description" v-html="description"></p>
        <ul class="menu-button">
           <li v-for="item in linkMenu" :key="item.titleLink" class="menu-button__item">
                <router-link tag="button" :to="item.link" class="button button_white">
                {{item.name}}
                </router-link>
           </li>
        </ul>
    </div>
</template>
<script>
import { HTTP, Routes } from '../API';
export default {
    props:{
        langContent:{type: String, default: 'ru'},
    },
    
    data(){
        return{
            linkMenu:[],
            description: null,
            title: null,
            error: {}
        }
    },
    methods:{
        getContent(){
                HTTP.get(Routes.menu)
                .then(  response => {
                    this.linkMenu = response.data.menu
                    // console.log('this.linkMenu: ', this.linkMenu);

                })
                .catch( error => {
                console.log(error);
                });
                HTTP.get(Routes.err_page)
                .then(  response => {
                    this.error = response.data.err_page
                    this.description = response.data.err_page.description,
                    this.title = response.data.err_page.title

                })
                .catch( error => {
                console.log(error);
                });

        }
    },
    mounted() {
        this.getContent();
    },
    created(){
        // Ссылки для меню
        // let objRouter = new Object();
        // let router = this.$router.options.routes
        // for (let index = 1; index < router.length; index++) {
        //     if(index < 6){
        //         objRouter.titleLink = router[index].name
        //         objRouter.link = router[index].path
        //         this.linkMenu.push(Object.assign({}, objRouter))
        //     }
        // } 
    }
}
</script>
